import React, { Component } from 'react'
import SanityBlockContent from '@sanity/block-content-to-react'

class BlockContent extends Component {
	constructor(props) {
		super(props)
		this.state = {
			className: 'rte',
			serializers: {
				types: {
					block: BlockRenderer,
					quote: (props) => (
						<blockquote>
							{props.node.quote}
							<cite>{props.node.cite}</cite>
						</blockquote>
					),
				},
				marks: {
					link: (props) => (
						<a href={props.mark.href} id={props.mark.id ? props.mark.id : ''} target={props.mark.blank ? '_black' : '_self'}>
							{props.children}
						</a>
					),
				},
			},
		}
	}

	render() {
		return <SanityBlockContent {...this.props} {...this.state} projectId="z925j2ge" dataset="production" />
	}
}

const BlockRenderer = (props) => {
	const style = props.node.style || 'normal'

	switch (style) {
		case 'h1':
			return <h1>{props.children}</h1>
		case 'h2':
			return <h2>{props.children}</h2>
		case 'h3':
			return <h3>{props.children}</h3>
		case 'h4':
			return <h4>{props.children}</h4>
		case 'small':
			return <p className="small">{props.children}</p>
		case 'large':
			return <p className="large">{props.children}</p>
		case 'xlarge':
			return <p className="x-large">{props.children}</p>
		default:
			return <p>{props.children}</p>
	}
}

export default BlockContent
