import React, { Component, useContext, useEffect } from 'react'
import { DataConsumer } from '../data/DataProvider'
import Banner from '../components/Banner/Banner'
import Slider from '../components/Slider/Slider'
import BlockContent from '../components/BlockContent/BlockContent'

class LiveGallery extends Component {
	componentDidMount() {
		document.title = 'Live Gallery | John Rich Artwork'
	}

	render() {
		// filter out artwork that's not for sale
		const artwork = this.context.artwork.filter((e) => e.liveGallery === true)
		const page = this.context.getByKey('pages', 'slug', 'live-gallery')

		return (
			<div className="container">
				<Banner title={page.title} />
				<Slider imageObjs={artwork} />
				{page.content && <BlockContent blocks={page.content} />}
			</div>
		)
	}
}

LiveGallery.contextType = DataConsumer

export default LiveGallery
