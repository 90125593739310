import React from 'react'
import { NavLink } from 'react-router-dom'
import { imageUrlBuilder } from '../helpers/helpers'

import './navigation-secondary.scss'

const NavigationSecondary = (props) => {
	return (
		<>
			{props.items && props.items.length > 0 && (
				<nav className="secondary-menu">
					<div className="row justify-content-center">
						{props.items.map(function (e, idx) {
							let slug = props.linkPrefix

							if (typeof e.showInstead != 'undefined') {
								slug += e.showInstead.slug.current
							} else {
								slug += e.slug.current
							}

							return (
								<div className="px-1 col-4 col-md-6" key={idx}>
									<div className="menu-item">
										<NavLink to={slug}>
											{e.image && <img src={imageUrlBuilder(e.image).fit('crop').width(220).height(146).url()} />}
											{!e.image && <img alt="no image" />}

											<div className="overlay">
												<div className="col my-auto" style={{ padding: 0 }}>
													{e.title}
												</div>
											</div>
										</NavLink>
									</div>
								</div>
							)
						})}
					</div>
				</nav>
			)}
		</>
	)
}

export default NavigationSecondary
