import React, { Component } from 'react'
import { imageUrlBuilder } from '../helpers/helpers'
import ImageGallery from 'react-image-gallery'

import './slider.scss'

class Slider extends Component {
	render() {
		if (this.props.imageObjs.length == 0) return null

		let items = this.props.imageObjs.map(function (e, idx) {
			const img = imageUrlBuilder(e.image).ignoreImageParams().fit('scale')
			const desc =
				(typeof e.title == 'undefined' ? '' : e.title) +
				' ' +
				(typeof e.size == 'undefined' ? '' : e.size) +
				' ' +
				(typeof e.material == 'undefined' ? '' : e.material) +
				' ' +
				(typeof e.caption == 'undefined' ? '' : e.caption)

			return {
				original: img.height(550).url(),
				thumbnail: img.height(200).url(),
				// srcSet: [
				// 	img.height(600).url() + ' 300w',
				// 	img.height(600).url() + ' 600w',
				// ],
				// sizes: [
				// 	'(min-width: 576px) 280px',
				// ]
				originalAlt: e.title,
				description: desc.trim(),
			}
		})

		const settings = {
			// https://github.com/xiaolin/react-image-gallery
			items: items,
			showThumbnails: true,
			infinite: false,
			showBullets: false,
			showPlayButton: false,
			showFullscreenButton: true,
			useBrowserFullscreen: false,
			showIndex: true,
			lazyLoad: true,
		}

		return (
			<div className="slider row align-items-center">
				<div className="col-sm-12">
					<ImageGallery {...settings} />
				</div>
			</div>
		)
	}
}

export default Slider
