import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { DataConsumer } from '../../data/DataProvider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './navigation.scss'

class Navigation extends Component {
	constructor(props) {
		super(props)
		this.toggleNavbar = this.toggleNavbar.bind(this)
		this.state = { collapsed: true }
	}

	toggleNavbar() {
		this.setState({ collapsed: !this.state.collapsed })
	}

	render() {
		const exhibition = this.context.getByKey('pages', '_id', '56c37bde-11b8-4e8b-a8cb-7f11cd9d22e2')
		const writing = this.context.getByKey('pages', '_id', '71c9b505-1e60-4462-864e-e08c4209404e')
		const shop = this.context.getByKey('pages', '_id', 'b825c8f7-d19b-42cf-9371-a3d13a2c2f20')
		const about = this.context.getByKey('pages', '_id', '1045c541-ee49-4398-be7c-0735120cc486')
		const contact = this.context.getByKey('pages', '_id', '3a5a8815-87c4-4567-9950-de1f1c63943f')
		const liveGallery = this.context.getByKey('pages', '_id', '6499833b-35a1-4135-927d-339e8885ab0e')

		// shows or hides the mobile burger menu (so we don't need bootstrap js)
		const collapsed = this.state.collapsed
		const classManu = collapsed ? 'justify-content-end collapse navbar-collapse' : 'justify-content-end navbar-collapse show'
		const classBurger = collapsed ? 'navbar-toggler collapsed' : 'navbar-toggler'

		return (
			<nav className="navbar navbar-dark navbar-expand-lg">
				<NavLink to="/" className="navbar-brand">
					John Rich
				</NavLink>
				<div className={classManu}>
					<div className="navbar-nav">
						<NavLink onClick={this.toggleNavbar} className="nav-link highlight" to={'/' + exhibition.slug.current}>
							{exhibition.name}
						</NavLink>
						<NavLink onClick={this.toggleNavbar} className="nav-link" to="/online-gallery">
							Online Gallery
						</NavLink>
						<NavLink onClick={this.toggleNavbar} className="nav-link" to={'/' + writing.slug.current}>
							{writing.name}
						</NavLink>
						<NavLink onClick={this.toggleNavbar} className="nav-link" to={'/' + shop.slug.current}>
							{shop.name}
						</NavLink>
						<NavLink onClick={this.toggleNavbar} className="nav-link" to={'/' + about.slug.current}>
							{about.name}
						</NavLink>
						<NavLink onClick={this.toggleNavbar} className="nav-link" to={'/' + contact.slug.current}>
							{contact.name}
						</NavLink>
					</div>
				</div>
				<div className="social navbar-nav">
					<a className="nav-link" target="_black" href="https://facebook.com/johnrichartwork">
						<FontAwesomeIcon icon={['fab', 'facebook']} />
					</a>
					<a className="nav-link" target="_black" href="https://www.instagram.com/johnrichartwork">
						<FontAwesomeIcon icon={['fab', 'instagram']} />
					</a>
				</div>
				<button className={classBurger} onClick={this.toggleNavbar}>
					{/* <span className="navbar-toggler-icon"></span> */}
					Menu
				</button>
			</nav>
		)
	}
}

Navigation.contextType = DataConsumer

export default Navigation
