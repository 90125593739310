import React, { Component } from 'react'

import './banner.scss'

class Banner extends Component {
	render() {
		return (
			<div className="banner">
				<h1>{this.props.title}</h1>
			</div>
		)
	}
}

export default Banner
