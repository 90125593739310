import React, { Component } from 'react'
import { DataConsumer } from '../data/DataProvider'
import BlockContent from '../components/BlockContent/BlockContent'
import Banner from '../components/Banner/Banner'
import Sidebar from '../components/Sidebar/Sidebar'
import Error from '../components/Error'

class Page extends Component {
	render() {
		const slug = this.props.match.params.slug
		let page = this.context.getByKey('pages', 'slug', slug)

		if (typeof page == 'undefined') {
			page = this.context.getByKey('writing', 'slug', slug)
		}

		document.title = page.title + " - John's Artwork"

		if (typeof page == 'undefined') return <Error />

		return (
			<div className="container">
				<div className="row">
					<div className="col">
						<Banner title={page.title} />
						<Sidebar slug={slug} />
						{page.content && <BlockContent blocks={page.content} imageOptions={{ w: 320, fit: 'clip' }} />}
					</div>
				</div>
			</div>
		)
	}
}

Page.contextType = DataConsumer

export default Page
