import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { DataConsumer } from '../../data/DataProvider'
import './sidebar.scss'

class Sidebar extends Component {
	constructor(props) {
		super(props)
	}

	render() {
		let buttons = []

		switch (this.props.slug) {
			case 'about-the-artist':
			case 'timeline':
			case 'photos':
				buttons.push(this.context.getByKey('pages', 'slug', 'about-the-artist'))
				buttons.push(this.context.getByKey('pages', 'slug', 'timeline'))
				buttons.push(this.context.getByKey('pages', 'slug', 'photos'))
				break
			case 'exhibition':
			case 'live-gallery':
			case 'reasons-to-visit-the-pop-up-gallery-with-garden':
			case 'reasons-to-visit-shere':
			case 'how-to-get-here':
			case 'venue-and-access-information':
			case 'sponsors':
			case 'press-information':
				buttons.push(this.context.getByKey('pages', 'slug', 'exhibition'))
				buttons.push({ slug: { current: 'calendar' }, button: 'CALENDAR', name: 'calendar' })
				// buttons.push(this.context.getByKey('pages', 'slug', 'live-gallery'))
				buttons.push(this.context.getByKey('pages', 'slug', 'reasons-to-visit-the-pop-up-gallery-with-garden'))
				buttons.push(this.context.getByKey('pages', 'slug', 'reasons-to-visit-shere'))
				buttons.push(this.context.getByKey('pages', 'slug', 'how-to-get-here'))
				buttons.push(this.context.getByKey('pages', 'slug', 'venue-and-access-information'))
				buttons.push(this.context.getByKey('pages', 'slug', 'supported-charities'))
				buttons.push(this.context.getByKey('pages', 'slug', 'sponsors'))
				buttons.push(this.context.getByKey('pages', 'slug', 'press-information'))
				break
			case 'supported-charities':
			case 'wwf-uk':
			case 'oakleaf':
				buttons.push(this.context.getByKey('pages', 'slug', 'exhibition'))
				buttons.push(this.context.getByKey('pages', 'slug', 'supported-charities'))
				buttons.push(this.context.getByKey('pages', 'slug', 'wwf-uk'))
				buttons.push(this.context.getByKey('pages', 'slug', 'oakleaf'))
				break
			case 'writings':
				this.context.writing.map((item) => {
					if (item.enabled !== true) return
					buttons.push(this.context.getByKey('writing', 'slug', item.slug.current))
				})
				break
		}

		if (buttons.length == 0) return null

		return (
			<div className="sidebar float-md-right ml-md-5 mb-3">
				{buttons.map(function (e, idx) {
					return (
						<NavLink className="btn btn-light btn-block" to={'/' + e.slug.current} role="button" key={idx}>
							{e.button && e.button.length > 0 ? e.button : e.name}
						</NavLink>
					)
				})}
			</div>
		)
	}
}

Sidebar.contextType = DataConsumer

export default Sidebar
