import sanityClient from '../../data/SanityClient'
import sanityImageUrlBuilder from '@sanity/image-url'

export function imageUrlBuilder(source) {
	return sanityImageUrlBuilder(sanityClient).image(source)
}

// remove any images that don't belong to the supplied category
export function filterByCategory(objArray, filter) {
	return objArray.filter(function (e) {
		for (var i = 0; i < e.categories.length; i++) {
			if (e.categories[i].slug.current == filter) return true
		}
	})
}

export function shuffle(o) {
	for (var j, x, i = o.length; i; j = parseInt(Math.random() * i), x = o[--i], o[i] = o[j], o[j] = x);
	return o
}
