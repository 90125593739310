import React, { Component } from 'react'
import { DataConsumer } from '../data/DataProvider'
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs'
import Slider from '../components/Slider/Slider'
import NavigationSecondary from '../components/NavigationSecondary/NavigationSecondary'
import { filterByCategory } from '../components/helpers/helpers'

class OnlineGallery extends Component {
	render() {
		const slug = typeof this.props.match.params.category === 'undefined' ? 'online-gallery' : this.props.match.params.category
		const category = this.context.getByKey('categories', 'slug', slug)

		const childCats = this.context.categories.filter((c) => typeof c.parent !== 'undefined' && c.parent.slug.current == category.slug.current)

		const topCats = this.context.categories.filter((c) => typeof c.parent !== 'undefined' && c.parent.slug.current == 'online-gallery')

		const siblingCats = this.context.categories.filter(
			(c) => typeof category.parent !== 'undefined' && typeof c.parent !== 'undefined' && c.parent.slug.current == category.parent.slug.current
		)

		const artwork = filterByCategory(this.context.artwork, category.slug.current)

		document.title = category.title + ' | John Rich Artwork'

		return (
			<div className="container-fluid">
				<div className="row">
					<div className="col text-center">
						<h1>{category.title}</h1>
						<Breadcrumbs seed={category} />
					</div>
				</div>
				<div className="row">
					<div className="col col-md-3 col-xl-2">
						<NavigationSecondary linkPrefix={'/online-gallery/'} items={topCats} />
					</div>
					<div className="col col-md-6 offset-xl-1">
						<Slider imageObjs={artwork} />
					</div>
					<div className="col col-md-3 offset-xl-1 col-xl-2 text-center sub">
						<NavigationSecondary linkPrefix={'/online-gallery/'} items={childCats} />
						{category.showSiblingCats && <NavigationSecondary linkPrefix={'/online-gallery/'} items={siblingCats} />}

						{category.description && <p>{category.description}</p>}
					</div>
				</div>
			</div>
		)
	}
}

OnlineGallery.contextType = DataConsumer

export default OnlineGallery
