import React, { Component } from 'react'
import Iframe from 'react-iframe'

import './calendar.scss'

class Calendar extends Component {
	render() {
		return (
			<div className="container-fluid">
				<div className="row">
					<div className="col text-center">
						<h1>Shere Pop-up Gallery Calendar</h1>
						<Iframe
							className="calendar"
							url="https://calendar.google.com/calendar/embed?height=600&wkst=2&bgcolor=%23ffffff&ctz=Europe%2FLondon&showTitle=0&showPrint=0&showTabs=1&showCalendars=0&showTz=1&hl=en_GB&src=am9obnJpY2hhcnR3b3JrQGdtYWlsLmNvbQ&src=ZW4tZ2IudWsjaG9saWRheUBncm91cC52LmNhbGVuZGFyLmdvb2dsZS5jb20&color=%23039BE5&color=%230B8043"
							style="border-width:0"
							width="900"
							height="600"
							frameBorder="0"
							scrolling="no"
						></Iframe>
					</div>
				</div>
			</div>
		)
	}
}

export default Calendar
