import React, { Component } from 'react'
import Navigation from '../Navigation/Navigation'

const Header = () => {
	return (
		<div className="container-fluid">
			<header>
				<Navigation />
			</header>
		</div>
	)
}

export default Header
