import React, { Component } from 'react'
import client from './SanityClient'
import Loading from '../components/Loading/Loading'

const DataContext = React.createContext()

export class DataProvider extends Component {
	constructor(props) {
		super(props)
		this.state = {
			appLoading: true,
			appLoadingError: false,
			pages: [],
			categories: [],
			writing: [],
			artwork: [],

			getByKey: this.getByKey.bind(this),
		}

		this.bootstrap()
	}

	bootstrap = async () => {
		this.setState({
			appLoading: true,
			appLoadingError: false,
		})

		try {
			const queryPages = '*[_type == "page"]'
			const queryCategories = '*[_type == "category"] { ..., parent->{slug}, showInstead->{slug} } | order(order asc)'
			const queryArtwork = '*[_type == "artwork" && defined(categories)] { ..., "categories": categories[]->{title, slug, image} } | order(order asc)'
			const queryWriting = '*[_type == "writing" && defined(categories)] { ..., "categories": categories[]->{title, slug, image} }'

			await this.fetch(queryPages, 'pages')
			await this.fetch(queryCategories, 'categories')
			await this.fetch(queryArtwork, 'artwork')
			await this.fetch(queryWriting, 'writing')

			this.setState({
				appLoading: false,
				appLoadingError: false,
			})
		} catch (error) {
			this.setState({
				appLoading: false,
				appLoadingError: true,
			})
		}
	}

	fetch(query, queryType) {
		return new Promise((resolve, reject) => {
			client
				.fetch(query)
				.then((result) => {
					let newState = {}
					newState[queryType] = result
					this.setState(newState)
					resolve(result)
				})
				.catch(() => {
					reject()
				})
		})
	}

	// supports any object with a slug (e.g. pages and categories)
	getBySlug(object, slug) {
		for (var k = 0; k < this.state[object].length; k++) {
			if (this.state[object][k].slug.current == slug) {
				return this.state[object][k]
			}
		}
	}

	// supports any object with a valid key (e.g. pages and categories)
	getByKey(object, key, value) {
		if (key == 'slug') return this.getBySlug(object, value)

		for (var k = 0; k < this.state[object].length; k++) {
			if (this.state[object][k][key] == value) {
				return this.state[object][k]
			}
		}
	}

	render() {
		return (
			<>
				{this.state.appLoading && <Loading />}
				{!this.state.appLoading && !this.state.appLoadingError && (
					<DataContext.Provider
						value={{
							appLoading: this.state.appLoading,
							appLoadingError: this.state.appLoadingError,
							pages: this.state.pages,
							categories: this.state.categories,
							artwork: this.state.artwork,
							writing: this.state.writing,
							getByKey: this.state.getByKey,
						}}
					>
						{this.props.children}
					</DataContext.Provider>
				)}
			</>
		)
	}
}

export const DataConsumer = DataContext.Consumer
