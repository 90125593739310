import React, { Component } from 'react'
import { DataConsumer } from '../data/DataProvider'
import NavigationSecondary from '../components/NavigationSecondary/NavigationSecondary'
import NavigationTertiary from '../components/NavigationTertiary/NavigationTertiary'
import Calendar from '../components/Calendar/Calendar'

// to do with the temp Home_ShowingGallery below
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs'
import Slider from '../components/Slider/Slider'
import { filterByCategory } from '../components/helpers/helpers'

import '../../styles/home.scss'

class Home extends Component {
	render() {
		const slug = typeof this.props.match.params.category === 'undefined' ? 'online-gallery' : this.props.match.params.category
		const category = this.context.getByKey('categories', 'slug', slug)
		const topCats = this.context.categories.filter((c) => typeof c.parent !== 'undefined' && c.parent.slug.current == 'online-gallery')

		document.title = category.title + ' | John Rich Artwork'

		return (
			<div className="container-fluid home">
				<div className="row">
					<div className="col text-center">
						<h1>Shere Pop-up gallery open days</h1>
					</div>
				</div>
				<div className="row">
					<div className="col d-none d-xl-block">
						<NavigationSecondary linkPrefix={'/online-gallery/'} items={topCats} />
					</div>
					<div className="col col-sm-12 col-xl-8 main">
						<p>
							Welcome to the website for the Pop-Up Gallery in Shere, Surrey UK
							<br />- representing the work of the English artist, John Rich.
						</p>
						<p>
							The Gallery with Garden re-opens in the Spring 2024 - Please check the calendar below for gallery opening times (updated regularly) and explore
							the website to learn more about the artist. Thank you.
						</p>
						<Calendar />
					</div>
					<div className="col-sm-12 col-md-4 offset-md-4 col-xl-2 offset-xl-0">
						<NavigationTertiary />
					</div>
				</div>
			</div>
		)
	}
}

Home.contextType = DataConsumer

class Home_ShowingGallery extends Component {
	render() {
		const slug = 'flora'
		const category = this.context.getByKey('categories', 'slug', slug)

		const childCats = this.context.categories.filter((c) => typeof c.parent !== 'undefined' && c.parent.slug.current == category.slug.current)

		const topCats = this.context.categories.filter((c) => typeof c.parent !== 'undefined' && c.parent.slug.current == 'online-gallery')

		const siblingCats = this.context.categories.filter(
			(c) => typeof category.parent !== 'undefined' && typeof c.parent !== 'undefined' && c.parent.slug.current == category.parent.slug.current
		)

		const artwork = filterByCategory(this.context.artwork, category.slug.current)

		document.title = category.title + ' | John Rich Artwork'

		return (
			<div className="container-fluid">
				<div className="row">
					<div className="col text-center">
						<h1>{category.title}</h1>
						<Breadcrumbs seed={category} />
					</div>
				</div>
				<div className="row">
					<div className="col col-md-3 col-xl-2">
						<NavigationSecondary linkPrefix={'/online-gallery/'} items={topCats} />
					</div>
					<div className="col col-md-6 offset-xl-1">
						<Slider imageObjs={artwork} />
					</div>
					<div className="col col-md-3 offset-xl-1 col-xl-2 text-center sub">
						<NavigationSecondary linkPrefix={'/online-gallery/'} items={childCats} />
						{category.showSiblingCats && <NavigationSecondary linkPrefix={'/online-gallery/'} items={siblingCats} />}

						{category.description && <p>{category.description}</p>}
					</div>
				</div>
			</div>
		)
	}
}

Home_ShowingGallery.contextType = DataConsumer

export default Home_ShowingGallery
