import React, { Component } from 'react'
import { DataConsumer } from '../data/DataProvider'
import Banner from '../components/Banner/Banner'
import Slider from '../components/Slider/Slider'
import BlockContent from '../components/BlockContent/BlockContent'

class Shop extends Component {
	componentDidMount() {
		document.title = 'Artwork For Sale | John Rich Artwork'
	}

	render() {
		// filter out artwork that's not for sale
		const artwork = this.context.artwork.filter(function (e) {
			return e.forSale === true
		})
		const page = this.context.getByKey('pages', 'slug', 'shop')

		return (
			<div className="container">
				<Banner title={page.title} />
				<Slider imageObjs={artwork} />
				{page.content && <BlockContent blocks={page.content} />}
			</div>
		)
	}
}

Shop.contextType = DataConsumer

export default Shop
