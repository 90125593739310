import React from 'react'

const Error = () => {
	return (
		<div>
			<p>Error: Page does not exist!!!11!!1</p>
		</div>
	)
}

export default Error
