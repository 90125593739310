import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { DataConsumer } from '../../data/DataProvider'
import './breadcrumbs.scss'

class Breadcrumbs extends Component {
	render() {
		let crumbs = []
		let currentCategory = this.props.seed

		crumbs.push(currentCategory)

		// if the seed is the only breadcrumb then we stop
		if (typeof currentCategory.parent == 'undefined') return ''

		// add all parent categories to the breadcrumb trail
		while (typeof currentCategory.parent != 'undefined') {
			currentCategory = this.context.getByKey('categories', 'slug', currentCategory.parent.slug.current)
			crumbs.push(currentCategory)
		}

		return (
			<>
				{' '}
				{crumbs && (
					<div className="row">
						<div className="col">
							<ol className="breadcrumbs" itemScope itemType="https://schema.org/BreadcrumbList">
								{crumbs.reverse().map(function (crumb, idx) {
									return (
										<li itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem" key={idx}>
											<NavLink to={crumb.slug.current} itemType="https://schema.org/Thing" itemProp="item">
												<span itemProp="name">{crumb.title}</span>
											</NavLink>
											<meta itemProp="position" content={idx + 1} />
										</li>
									)
								})}
							</ol>
						</div>
					</div>
				)}{' '}
			</>
		)
	}
}

Breadcrumbs.contextType = DataConsumer

export default Breadcrumbs
