import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { DataConsumer } from '../../data/DataProvider'
import { imageUrlBuilder } from '../helpers/helpers'

import './navigation-tertiary.scss'

class NavigationTertiary extends Component {
	render() {
		const chosenArtworks = [
			'cbe95c2e-817e-4f31-9d50-e04bc1364c20',
			'04791b5c-5c87-447b-8294-27bddbbc898f',
			'21c84169-0bd4-421a-9a79-e520dd3c595e',
			'6b14ffa6-0ccd-4036-823a-53fad39a2775',
			'eeeae68a-3980-4795-a338-c0c9c744d303',
			'f923d997-90ad-4cbc-86a3-8437d72f1b52',
		]

		const artworks = this.context.artwork.filter((a) => chosenArtworks.includes(a._id))

		const links = [
			{
				label: 'Pop-Up Gallery',
				slug: '/exhibition',
				image: artworks[0].image,
				color: 'red',
			},
			{
				label: 'Online Gallery',
				slug: '/online-gallery/interiors',
				image: artworks[1].image,
			},
			{
				label: 'Writings',
				slug: '/writings',
				image: artworks[2].image,
			},
			{
				label: 'Sales',
				slug: '/shop',
				image: artworks[3].image,
			},
			{
				label: 'About',
				slug: '/about-the-artist',
				image: artworks[4].image,
			},
			{
				label: 'Contact',
				slug: '/contact',
				image: artworks[5].image,
			},
		]

		return (
			<>
				<nav className="tertiary-menu secondary-menu">
					<div className="row justify-content-center">
						{links.map(function (link, idx) {
							return (
								<div className="px-1 col-4 col-md-6" key={idx}>
									<div className="menu-item">
										<NavLink to={link.slug}>
											{link.image && <img src={imageUrlBuilder(link.image).fit('crop').width(220).height(146).url()} />}

											<div className="overlay">
												<div className="col my-auto" style={{ padding: 0 }}>
													{link.label}
												</div>
											</div>
										</NavLink>
									</div>
								</div>
							)
						})}
					</div>
				</nav>
			</>
		)
	}
}

NavigationTertiary.contextType = DataConsumer

export default NavigationTertiary
