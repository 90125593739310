import '@babel/polyfill' // apparently it's better to put this into webpack config
import React, { Component } from 'react'
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'
import { DataProvider } from './data/DataProvider'

import './components/FontAwesome/FontAwesome'

import Page from './pages/Page'
import OnlineGallery from './pages/OnlineGallery'
import Shop from './pages/Shop'
import LiveGallery from './pages/LiveGallery'
import Calendar from './components/Calendar/Calendar'
import Header from './components/Header/Header'
import Error from './components/Error'
import Home from './pages/Home'

class App extends Component {
	render() {
		return (
			<BrowserRouter>
				<DataProvider>
					<Header />
					<Switch>
						<Route path="/" component={Home} exact />
						<Route path="/online-gallery" render={() => <Redirect to="/online-gallery/interiors" />} exact />
						<Route path="/online-gallery/online-gallery" render={() => <Redirect to="/online-gallery/interiors" />} exact />
						<Route path="/online-gallery/other" render={() => <Redirect to="/online-gallery/objects" />} exact />
						<Route path="/online-gallery/studies" render={() => <Redirect to="/online-gallery/studies-interiors" />} exact />
						<Route path="/online-gallery/:category" component={OnlineGallery} />
						<Route path="/live-gallery" component={LiveGallery} />
						<Route path="/calendar" component={Calendar} />
						<Route path="/shop" component={Shop} />
						<Route path="/:slug" component={Page} />
						<Route component={Error} />
					</Switch>
					<footer style={{ height: 45 }} />
				</DataProvider>
			</BrowserRouter>
		)
	}
}

export default App
