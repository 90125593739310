import React, { Component } from 'react'
import './loading.scss'

class Loading extends Component {
	render() {
		return <div id="loading">Loading..</div>
	}
}

export default Loading
